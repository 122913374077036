





































import html2canvas from "html2canvas";
import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import Loading from "@monorepo/uikit/src/components/tableViews/Loading.vue";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import axios from "axios";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export default defineComponent({
  name: "BugReport",
  components: {
    FormInputElement,
    Loading,
  },
  async created() {
    try {
      this.isLoading = true;
      this.base64 = await this.getScreenshot();
    } catch (e) {
      showNotification("Ошибка формирования скриншота");
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      isLoading: true,
      base64: "",
      values: {},
      blobScreen: new Blob(),
    };
  },

  methods: {
    async getScreenshot() {
      this.blobScreen = new Blob();
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;

      return html2canvas(document.body, {
        width,
        height,
        windowWidth: width,
        windowHeight: height,
        y: window.scrollY,
      }).then((canvas) => {
        canvas.toBlob((blob) => {
          this.blobScreen = blob as Blob;
        }, "image/png");

        return canvas.toDataURL("image/png");
      });
    },
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    async sendBugReport(data: { message: string }) {
      if (!data.message) {
        showNotification("Отсутствует описание ошибки");
        return;
      }
      const formData = new FormData();
      formData.append("screenshot", this.blobScreen, "bugreport.png");
      formData.append("message", data.message || "");
      try {
        await axios.post(getFullPath(QUERY_PATH.GET_BUG_REPORT), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        showNotification("Сообщение об ошибке отправлено", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        showNotification(e.response?.data?.message);
        console.error(e);
      }
      this.closeModal();
    },
  },
});
